export interface Link {
    id: number;
    name: string;
    availability: number;
    background_image: string;
    background_mobile_image: string;
    primary_color: string;
    email_template: string;
    price: string;
    cancel_option: string;
    allow_any: boolean;
    installments: number;
    i_rate: number;
    random_code: boolean;
    participant_type: string;
    participant_file: boolean;
    additional_info: string;
    show_terms: boolean;
    rentpass_event: number;
    allowed_participants?: Array<string>;
    facial_recognition: boolean;
}

export interface LinkRequest {
    token: string;
    user_id: string;
    id: number;
    name: string;
    availability: number;
    background_image: string;
    background_mobile_image: string;
    primary_color: string;
    email_template: string;
    price: string;
    cancel_option: string;
    allow_any: boolean;
    installments: number;
    i_rate: number;
    random_code: boolean;
    participant_type: string;
    participant_file: boolean;
    additional_info: string;
    show_terms: boolean;
    event: number;
    allowed_participants?: Array<string>;
    facial_recognition: boolean;
}

export interface RsvpEventRequest {
    update: boolean;
    token: string;
    user_id: number;
    id?: number;
    rentpass_id: number;
    required_fields: string;
    auto_send_email: boolean;
    name: string;
    date: string;
    owner: string;
    place: string;
    terms: string;
    blinket_id: string;
    sympla_id: string;
    sympla_token: string;
    hotmart_id: string;
    hotmart_client_id: string;
    hotmart_client_secret: string;
    hotmart_basic_auth: string;
}

export interface RsvpEventResponse {
    id: number;
    rentpass_id: number;
    required_fields: string;
    auto_send_email: boolean;
    name: string;
    date: string;
    owner: string;
    place: string;
    terms: string;
    blinket_id: string;
    sympla_id: string;
    sympla_token: string;
    hotmart_id: string;
    hotmart_client_id: string;
    hotmart_client_secret: string;
    hotmart_basic_auth: string;
}

export interface RecipientRequest {
    token: string;
    user_id: number;
    rentpass_event: number;
    id_pg: string;
}

export interface SymplaIntegrationRequest {
    token: string;
    user_id: number;
    event_id: number;
}

export interface RegistrationTokenRequest {
    account_type: string;
    rentpass_event: number;
    terms: string;
    created_by: number;
    auth_token: string;
}

export interface OrderResponse {
    id: number;
    owner: number;
    price: number;
    status: string;
    created: string;
    paid: string;
    cancelled: string;
    event: number;
    link: number;
    ticket_sent: boolean;
    payment_method: string;
    installments: number;
    payer_name: string;
    payer_email: string;
    payer_phone: string;
    payer_cpf_cnpj: string;
    payer_doc_type: string;
    payer_address_street: string;
    payer_address_number: string;
    payer_address_neighborhood: string;
    payer_address_city: string;
    payer_address_state: string;
    payer_address_compementary: string;
    payer_address_zip_code: string;
    pagarme_id: string;
    link_details: LinkRequest;
}


export enum OrderStatus {
    'CREATED' = 'Gerado',
    'WAITING CONFIRMATION'= 'Pendente',
    'PAID'= 'Pago',
    'PAYMENT FAILED'= 'Falha'
}

export enum WithdrawStatus {
    'pending' = 'Pendente',
    'failed'= 'Falha',
    'created'= 'Criada',
    'processing' = 'Processando',
    'transferred' = 'Transferida',
    'canceled' = 'Cancelada'
}


export enum PaymentMethod {
    'credit_card' = 'Cartão de Crédito',
    'pix'= 'Pix',
    'boleto' = 'Boleto'
}

export interface BalanceResponse {
    currency: string;
    available_amount: number;
    waiting_funds_amount: number;
    transferred_amount: number;
    recipient: {
        id: string;
        name: string;
        email: string;
        document: string;
        type: string;
        payment_mode: string;
        status: string;
        creted_at: string;
        updated_at: string;
    }
}

export interface WithdrawalsResponse {
    paging: any;
    data: {
        id: string;
        amount: number;
        status: string;
        created_at: string;
        updated_at: string;
        bank_account: {
            id: string;
            holder_name: string;
            holder_type: string;
            holder_document: string;
            bank: string;
            branch_number: string;
            branch_check_digit: string;
            account_number: string;
            account_check_digit: string;
            type: string;
            status: string;
            created_at: string;
            updated_at: string;
        },
        recipient: {
            id: string;
            name: string;
            email: string;
            document: string;
            type: string;
            payment_mode: string;
            status: string;
            creted_at: string;
            updated_at: string;
        }
    }[]
}