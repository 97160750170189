import styled from 'styled-components';
import { TButton } from '../TButton';

interface ButtonProps {
    backgroundColor: string;
}

export const CloseButtonRow = styled.div`
    display: flex;
    width: 100%;
`

export const ButtonRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h2`
    padding-top: 0px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
`;


export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 20px;
    width: 60%;
`;
