import React, { useEffect, useState } from 'react'
import { Background, Balance, BalanceCard, BalanceContainer, FilterContainer, FilterFields, SmallerColumn, StatusButton, Title, WithdrawalsContainer } from './styled'
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap'
import RsvpService from '@/Services/rsvp/RsvpService'
import { BalanceResponse, OrderResponse, OrderStatus, PaymentMethod, WithdrawalsResponse, WithdrawStatus } from '@/Services/api/models/rsvpModels';
import { FormatCESSDate, formatDateToString, formatDjangoDate } from '@/util/FormatDate';
import ModalLoading from '@/Components/ModalLoading';
import { toast } from 'react-toastify';
import { FormButton } from '@/Components/FormButton';
import ModalWithdraw from '@/Components/WithdrawModal';

interface Props {
    eventId: string;
}

export default function Financeiro(props:Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [balance, setBalance] = useState<BalanceResponse>()
    const [withdrawals, setWithdrawals] = useState<WithdrawalsResponse>()
    const [withdraw, setWithdraw] = useState<boolean>(false)

    const getBalance = async () => {
        setIsLoading(true)
        const data = await RsvpService.getRecipientBalance(props.eventId)
        setBalance(data)
        setIsLoading(false)
    }

    const getWithdrawals = async () => {
        setIsLoading(true)
        const data = await RsvpService.getRecipientWithdrawals(props.eventId)
        setWithdrawals(data)
        setIsLoading(false)
    }

    useEffect(() => {
        getBalance()
        getWithdrawals()
    },[])


  return (
    <Background>
        <ModalLoading isActive={isLoading}/>
        <ModalWithdraw available_amount={balance?.available_amount} isActive={withdraw} event={props.eventId} hideModal={() => setWithdraw(false)} onButtonClick={() => {}} onInputClick={() => {}}/>
        <Title>
            Financeiro
        </Title>
        <BalanceContainer>
            <BalanceCard>
                Saldo Disponível
                {!isLoading &&
                    <Balance>
                        R$ {Number(balance?.available_amount/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
                <FormButton onClick={() => setWithdraw(true)}>
                    Nova transferência
                </FormButton>
            </BalanceCard>
            <BalanceCard>
                Saldo a Receber
                {!isLoading &&
                    <Balance>
                        R$ {(Number(balance?.waiting_funds_amount)/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
            </BalanceCard>
            <BalanceCard>
                Saldo transferido
                {!isLoading && 
                    <Balance>
                        R$ {(Number(balance?.transferred_amount)/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
            </BalanceCard>
        </BalanceContainer>
        <WithdrawalsContainer>
            Transferências
            <div className={'table'}>
                <Table hover id='orderTable'>
                    <thead>
                        <tr>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Criado</th>
                            <th>Atualizado</th>
                            <th>Banco</th>
                            <th>Agência</th>
                            <th>Conta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {withdrawals?.data?.map((w) => 
                            <tr>
                                <td>R$ {(Number(w?.amount)/100)?.toFixed(2)?.replace('.', ',')}</td>
                                <SmallerColumn>
                                    <StatusButton className={['pending', 'processing']?.includes(w?.status)? 'warning' : ['failed', 'canceled']?.includes(w?.status)? 'inactive' : 'active'}>
                                        {WithdrawStatus[w?.status]}
                                    </StatusButton>
                                </SmallerColumn>
                                <td>{FormatCESSDate(w?.created_at)}</td>
                                <td>{FormatCESSDate(w?.updated_at)}</td>
                                <td>{w?.bank_account?.bank}</td>
                                <td>{`${w?.bank_account?.branch_number ?? ''}${w?.bank_account?.branch_check_digit? '-' : ''}${w?.bank_account?.branch_check_digit ?? ''}`}</td>
                                <td>{`${w?.bank_account?.account_number ?? ''}${w?.bank_account?.account_check_digit? '-' : ''}${w?.bank_account?.account_check_digit ?? ''}`}</td>
                            </tr>
                            
                        )}
                    </tbody>
                </Table>
            </div>
        </WithdrawalsContainer>
    </Background>
  )
}
