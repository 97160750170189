import { useTheme } from 'styled-components';
import {
    ButtonRow,
    CloseButtonRow,
    ContentContainer,
    ModalContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import CloseButton from '../CloseButton';
import { Col, Form, Row } from 'react-bootstrap';
import { EventResponse } from '@/Services/api/models/eventModel';
import { useState } from 'react';
import { FormButton } from '../FormButton';
import ApiService from '@/Services/api/ApiService';
import RsvpService from '@/Services/rsvp/RsvpService';
import { toast } from 'react-toastify';


interface Props {
    event: string;
    available_amount: number;
    hideModal: () => void;
    onButtonClick: () => void;
    onInputClick: (file) => void;
    isActive: boolean;
}

export default function ModalWithdraw(props: Props) {
    const [amount, setAmount] = useState<number>()

    const handleWithdraw = async () => {
        console.log(amount)
        if(!amount || amount < 5) {
            toast.error('O valor mínimo para transferência é R$ 5,00.');
            return
        } 
        if(amount && amount*100 > props.available_amount) {
            toast.error('Saldo insuficiente para a transferência.');
            return
        }  
        try {
            const response = await RsvpService.createWithdrawal(props.event?.toString(), (amount*100))
            toast.success('Transferência criada com sucesso!')
            props.hideModal()
        } catch (error: any) {
            toast.error(
                error.response?.data?.message || 
                'Houve um erro ao criar a transferência. Por favor, tente novamente.'
            );
            props.hideModal()
        }
        
    }

    return (
    <>
        <TModal
            active={props.isActive}
            width='60vw'
            height='60vh'
            hideModal={() => undefined}
            footer={
                <>
                </>
            }
        >
            <ModalContainer>
                <CloseButtonRow>
                    <a onClick={() => props.hideModal()}>
                        <CloseButton />
                    </a>
                </CloseButtonRow>
                <Title>Nova Transferência</Title>
                <ContentContainer>
                    <p>{`Saldo disponível: R$ ${(Number(props.available_amount)/100)?.toFixed(2)?.replaceAll('.',',')}`}</p>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Valor a transferir
                        </Form.Label>
                        <Form.Control type='number' onChange={(e) => setAmount(Number(e?.target?.value))} min={0} step={0.01} max={Number(props.available_amount)/100} />
                    </Form.Group>
                    <ButtonRow>
                        <FormButton onClick={handleWithdraw}>
                            Solicitar transferência
                        </FormButton>
                    </ButtonRow>
                </ContentContainer>
            </ModalContainer>
        </TModal>
    </>
    );
}
